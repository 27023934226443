import auth from '@/services/auth';

export const ROLE_ADMINISTRATOR = 'Administrator';
export const ROLE_AGENCY = 'Agency';
export const ROLE_AGENCY_MANAGER = 'AgencyManager';
export const ROLE_MUSKETEER = 'Musketeer';
export const ROLE_COLONEL = 'Colonel';
export const ROLE_LIEUTENANT_COLONEL = 'LieutenantColonel';
export const ROLE_EVENT_MANAGER = 'EventManager';

export const ROLES = [
  ROLE_AGENCY,
  ROLE_AGENCY_MANAGER,
  ROLE_MUSKETEER,
  ROLE_LIEUTENANT_COLONEL,
  ROLE_COLONEL,
  ROLE_EVENT_MANAGER,
  ROLE_ADMINISTRATOR,
];

export const ROLES_MAPPING = {
  '57d67d8b-8799-4791-8bf4-10cfd8f90e98': ROLE_ADMINISTRATOR,
  'ac4bfa8f-1c88-4353-92a5-eee9a545bfb5': ROLE_AGENCY,
  '61efa165-d41f-4257-8d12-ed28c7ecbf5f': ROLE_AGENCY_MANAGER,
  '516f79a4-55c9-41fe-8218-286355abcde7': ROLE_MUSKETEER,
  '373ce96a-7619-4df8-8d38-026fa3d641d9': ROLE_COLONEL,
  'c7976a51-2984-4ceb-9cbe-11950f80b971': ROLE_LIEUTENANT_COLONEL,
  '2e27c053-1442-4524-9461-28ea25ad3bde': ROLE_EVENT_MANAGER,
};

export function isUserAgency() {
  return auth.state.roles.some(role => [ROLE_AGENCY, ROLE_AGENCY_MANAGER].includes(role));
}

export function isUserAgencyManager() {
  return auth.state.roles.some(role => [ROLE_AGENCY_MANAGER].includes(role));
}

export function isUserEventManager() {
  return auth.state.roles.some(role => role === ROLE_EVENT_MANAGER);
}

export function isUserRedbull() {
  return auth.state.roles.some(role => [ROLE_MUSKETEER, ROLE_COLONEL, ROLE_LIEUTENANT_COLONEL, ROLE_EVENT_MANAGER].includes(role));
}

export function isUserAdministrator() {
  return auth.state.roles.some(role => role === ROLE_ADMINISTRATOR);
}

export function canApproveSelf() {
  return auth.state.roles.some(role => [ROLE_COLONEL, ROLE_EVENT_MANAGER].includes(role));
}

export function getUserRole() {
  let roleIndex = -1;
  for (let role of auth.state.roles) {
    let index = ROLES.indexOf(role);
    if (index > roleIndex) {
      roleIndex = index;
    }
  }

  return ROLES[roleIndex];
}
